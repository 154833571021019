import "./App.css"
import { Route, HashRouter, Routes } from "react-router-dom"
import Notice from "./pages/Notice"
import NZ from "./pages/NZ"

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/nz/:uid" element={<NZ />} />
        <Route path='/notice' element={<Notice />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
